import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from "gatsby";

const Nebulizer = () => {
    const [readMore, setReadMore] = useState(false)

    return (
        <StyledNebulizer>
            <TextWrapper>
                <H3>Medad Technology has used sonochemistry to create an innovative and compact new ultrasound nebulizer
                    that improves medicine delivery systems and creates a safer alternative for recreational delivery mechanisms.</H3>
                <Description>
                    <Left>
                        <H4>Medad Technology has developed a miniaturized hand-held nebulizer with a suite of companion
                            integrated chips capable of operating ultrasound transducers at high frequencies with
                            feedback control algorithms. Working on the principles of piezoelectric effect and
                            ultrasound cavitation, the nebulizer enables the aerosolization of dense and viscous
                            solutions, creating a fine mist.</H4>
                        <ShowMoreButton showMore={readMore} onClick={() => setReadMore(true)}>Show more</ShowMoreButton>
                        <ShowMore showMore={readMore}>
                            <H4>The inhalation technology is the first innovation that Medad Technology started with 4
                                years ago. To date, Medad Technology has invested US$50 million in R&D, in-house
                                manufacturing, product testing, consumer research, and securing regulatory approval of
                                its ultrasound technology in the UAE and international markets.</H4>
                        </ShowMore>
                    </Left>
                    <Separator></Separator>
                    <Right>
                        <ShowMore showMore={readMore}>
                            <H4>We plan to spend another US$100 million on smoking cessation research and potential applications in therapeutics over the next three years, as well as technology development in other industries.</H4>
                        </ShowMore>
                        <ShowMore showMore={readMore}>
                            <H4>In addition to its ultrasound technology, Medad Technology has invested in <Link to={'https://boom.health'} target={'_blank'}><Span>Boom Health</Span></Link>, a mobile
                                application for senior home care services. Boom Health is an innovative app helping
                                Canadians take control of their senior care.</H4>
                        </ShowMore>
                    </Right>
                </Description>
            </TextWrapper>
        </StyledNebulizer>
    )
}

export default Nebulizer

const StyledNebulizer = styled.div`
  padding: 12rem 9.4rem;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.05) 69%, rgba(149, 144, 217, 0.5) 100%);
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 6rem 2.4rem;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 12rem 15vw;
  }
`

const TextWrapper = styled.div`
`

const Separator = styled.div`
    border-left: 1px solid #E0E0E0;
`

const H3 = styled.h3`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  font-weight: 400;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`

const Description = styled.div`
  margin-top: 4.8rem;
  display: flex;
  flex-direction: row;
  //flex: 1;
  gap: 6.2rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 3rem;
    flex-direction: column;
    gap: 1rem;
  }
`

const ShowMoreButton = styled.button`
  margin-top: 2rem;
  display: none;
  text-decoration: none;
  border: none;
  background: none;
  color: #3B869D;
  font-family: Sequel45;
  font-size: 1.6rem;
  line-height: 2.2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: ${({ showMore }) => showMore ? 'none' : 'block'};
  }
`

const ShowMore = styled.div`
    @media ${({ theme }) => theme.breakpoints.mobile} {
      display: ${({ showMore }) => showMore ? 'block' : 'none'};
    }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  @media ${({theme}) => theme.breakpoints.mobile} {
    gap: 2rem;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  @media ${({theme}) => theme.breakpoints.mobile} {
    gap: 2rem;
  }
`

const H4 = styled.h4`
  font-family: Sequel45;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-family: Sequel45;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`

const Span = styled.span`
  color: #3B869D;
  text-decoration: underline solid #3B869D .3rem;
  text-underline-offset: .3rem;
`

import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../../components/healthtech-page/hero/Hero.js";
import Layout from '../../components/layout/Layout'
import Seo from '../../components/seo'
import Sonochemistry from "../../components/healthtech-page/sonochemistry/Sonochemistry";
import Applications from "../../components/healthtech-page/applications/Applications";
import Security from "../../components/healthtech-page/security/Security";
import OtherSectors from "../../components/healthtech-page/other-sectors/OtherSectors";
import Nebulizer from "../../components/healthtech-page/nebulizer/Nebulizer";

const HealthtechPage = (props) => {
    const {strapiHealthtech} = useStaticQuery(query);

    return (
        <Layout>
            <Seo
                title={strapiHealthtech.metaTitle}
                description={strapiHealthtech.metaDescription}
                keywords={strapiHealthtech.metaKeywords}
            />
            <Hero path={props.location.pathname}/>
            {/*<Sonochemistry />*/}
            <Nebulizer />
            <Applications />
            <Security />
            <OtherSectors hiddenIndex={1}/>
        </Layout>
    )
}

export default HealthtechPage

const query = graphql`
    query {
        strapiHealthtech {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
